import React from "react"
import NuvoImage from "../../components/NuvoImage"
import TheaterVideo from "@components/Theater/TheaterVideo"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function TopSection(props) {
  return (
    <>
      <div className="columns top-section white-back">
        <div className="column is-2"></div>
        <div className="column">
          <h1
            className="mobile-left has-text-centered"
            style={{ marginTop: "0" }}
          >
            {props.post.heading}
          </h1>

          <div
            className=""
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.topBlurb)
            )}
          ></div>
        </div>
        <div className="column is-2"></div>
      </div>

      {props.post.mainVideo.youtube && (
        <div className="columns has-text-centered about-main-theater-image">
          <div className="column is-full-width">
            <TheaterVideo
              buttonClass="contained"
              videoUrl={`https://www.youtube.com/watch?v=${props.post.mainVideo.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}
              language={props.language}
            >
              <NuvoImage
                cloudName="nuvolum"
                publicId={props.post.mainVideo.imageId}
                useAR
                width="auto"
                responsive
              ></NuvoImage>
            </TheaterVideo>
          </div>
        </div>
      )}
    </>
  )
}

export default TopSection
