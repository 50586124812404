import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import TextPhoto from "../components/TextPhoto/TextPhoto"
import PropContext from "../components/PropContext"
import { graphql } from "gatsby"

import TopSection from "./about/TopSection"
import OurMission from "./about/OurMission"
import MeetOurDoctors from "./about/MeetOurDoctors"
import OurReviews from "./about/OurReviews"
import MeetOurTeam from "./about/MeetOurTeam"
import ReasonsWhy from "./about/ReasonsWhy"
import OurOffice from "./about/OurOffice"
import BannerSection from "./about/BannerSection"
import LearnMoreCTA from "./about/LearnMoreCTA"
import OverlappedImageText from "./about/OverlappedImageText"
import InvertBackgroundColor from "./about/InvertBackgroundColor"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

import { getPostObj } from "../utils/utils";

const AboutUsPage = ({ pageContext, location, data }) => {

  var post;
  var language;
  let postObj = getPostObj(pageContext, data.allUniquePagesJson.nodes[0], data.allSpanishUniquePagesJson.nodes[0]);
  post = postObj.post;
  language = postObj.language;

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO 
          title={post.metaTitle} 
          description={post.metaDescription} 
          pathname={location.pathname} 
          schemaPage={post.schemaPage ? post.schemaPage : null} 
        />
        <div className="joshua-tree-content about-us">
          <TopSection post={post} language={language} />
          <OurMission post={post} language={language} sideColumnIs={4} />
          <MeetOurDoctors 
            post={post} 
            language={language}
            colorBack={false} 
          />
          <OurReviews post={post} language={language} />
          <ReasonsWhy post={post} language={language} />

          {post.textOverlappedImages.hasThisSection &&
            <OverlappedImageText 
              items={post.textOverlappedImages.items}
            />
          }
          
{/**
 * Meet Our Team section removed 8/29/2022 per request of client
 */}
          {/* <MeetOurTeam post={post} language={language} /> */}

          <OurOffice locations={2} post={post} language={language} />
          {post.bannerSection && post.bannerSection.hasThisSection && <BannerSection post={post} />}
          <LearnMoreCTA colorBack={true} post={post} language={language} />
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
          staffImages {	
            staffMember {	
              imageId	
              staffName	
              staffTitle	
            }	
          }
        }
        ourOffice {
          imageId
          text
          imageIdMobile
          locationCopy
          hasThisSection
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          useImageStrip
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
          imageStrip {
            youtube
            images {
              imageId
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          text
        }
        metaTitle
        metaDescription
        schemaPage
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        textOverlappedImages {
          hasThisSection,
          items {
            isReversed
            heading
            blurb
            imageId
            button {
              destination
              appearance
              buttonText
              href
            }
          }
        }
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
            }
          }
        }
        ourOffice {
          hasThisSection
          imageId
          imageIdMobile
          text
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          text
        }
        metaTitle
        metaDescription
        schemaPage
        title
        mainVideo {
          imageId
          youtube
          imageIdMobile
        }
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
  }
`

export default AboutUsPage
